.container {
  margin: 0;
  padding: 0;
  color: #333;
}

.variable-width .slick-slide p {
  background: blue;
  height: 300px;
  color: #fff;
  margin: 0px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center {
  opacity: 1;
  transition: all 300ms ease;
}
.content {
  padding: 0px;
  margin: auto;
  width: 100%;
}
.slick-slide .image {
  padding: 0px;
}
.slick-slide img {
  border: 0px solid #fff;
  display: block;
  margin: auto;
}
.slick-slide img.slick-loading {
  border: 0;
}
.slick-slider {
  margin: 0px auto 0px;
}
.slick-dots {
  margin-left: 0;
}
.slick-dots li button:before {
  color: #000;
  font-size: 13px;
  z-index: 1;
}

.slick-thumb {
  bottom: -45px;
}
@media (max-width: 768px) {
  .center {
    margin-left: -0px;
    margin-right: -0px;
  }
  .center .slick-center {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center {
    opacity: 1;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
.slick-arrow {
  /* color: black; */
}
.slick-arrow:hover {
  color: white;
}

.slick-arrow slick-prev slick-disabled {
  color: white;
}

.slick-prev:before{
    color: white;
    opacity: 1;
}

.slick-prev:before, .slick-next:before{
    color: white;
    opacity: 1;
    font-size: 32px;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: .85;
}


.slick-prev {
    left: 4px;
    z-index: 1;
}

.slick-next {
    right: 16px;
}