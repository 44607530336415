.body{
  height: 630px;
  background: #FFEC0D;
  margin-top: 95px;
  margin-left: -8px;
  margin-right: -8px; 
  display: flex;
  justify-content: space-around;
}
.imagem{
  width: 400px;
  height: 320px;
  margin-top: 311px;
  margin-left: -84px;
}
.div_parceria{
  border: 1px solid #FFFFFE;
  width: 418px;
  height: 555px;
  margin-top: 39px;
  background: #FFFFFE;
  border-radius: 25px;
}
.div_responsiva_form{
  display: flex;
  justify-content: flex-end;
}
.input_text{
  padding-left: 14px;
  margin-bottom: 12px;
  font-size: 13px;
  width: 316px;
  height: 41px;
  background: #F2EFEF;
  border: 1px solid rgba(194, 192, 192, 0.9);
  border-radius: 30px;
}
.input_select{
  padding-left: 14px;
  margin-bottom: 12px;
  width: 330px;
  height: 46px;
  background: #F2EFEF;
  border: 1px solid rgba(194, 192, 192, 0.9);
  border-radius: 30px;
}
.form{
  display: flex;
  flex-direction:column;
  width: 450px;
  align-items: flex-end;
}
.form input{
  padding: 08px 20px;
  margin-bottom: 12px;
  border: 1px solid black;
  border-radius: 15px;
}
.button{
  background-color: rgb(255, 255, 49);
  width: 300px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 30px;
  cursor: pointer;
}
.button :hover{
  background-color: yellow;
}
.divButton{
    text-align: center;
    margin: 20px;
}
.redirecionar{

    position: absolute;
    width: 120px;
    height: 50px;
    left: 0px;
    margin:20px;
    top: -5px;

  color: #403F3F;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
}
.seja{
  margin-top: 46px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 144%;
  /* or 33px */

  letter-spacing: 0.045em;

  color: #323131;
}
.venda{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 144%;
  /* or 27px */

  letter-spacing: 0.045em;

  color: #646363;
}
.texto_quando{
  width: 315px;
  margin-top: 49px;
  height: 237px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 47px;
  color: #323131;
}
.div_agradecimento{
  margin-top: -442px;
  margin-left: 505px;
  width: 423px;
}
.imagem_parceiro_agradecimento{
  width: 450px;
}

.radio{
  color: #FFEC0D;
  background: #FFEC0D;
  border: 1px solid #FFFFFE;
  border-radius: 5px;
}
.div_botao{
  text-align: center;
  margin: 20px;
}
.botao{
  cursor: pointer;
  box-sizing: border-box;
  width: 258px;
  height: 46px;
  left: 839px;
  top: 754px;
  background: #F9146B;
  border: 1px solid rgba(217, 30, 117, 0.9);
  border-radius: 30px;
}
.t_botao{
width: 225px;
height: 23px;
margin-top: 13px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #FFFFFE;
}
.recebemos{
  width: 505px;
    height: 110px;
    left: 186px;
    top: 309px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 67px;
    color: #323131;
}
.breve{
  width: 469px;
    height: 146px;
    left: 836px;
    top: 325px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 144%;
    letter-spacing: 0.045em;
    color: #646363;
}
@media(max-width: 1100px){
  .div_parceria{
    margin-left: auto;
    margin-top: -150px;
    z-index: 1;
  }
  .body{
    height: 1350px;
    display: grid;
  }
  .imagem{
    margin-top: 311px;
    margin-bottom: -22px;
    margin-left: 11px;
  }
  .div_responsiva_form{
    display: flex;
    justify-content: flex-start;
  }
}
@media(max-width:530px){
  .div_parceria{
    width: 388px;
    margin-left: 0px;
    margin-top: -70px;
    z-index: 1;
  }
  .imagem{
    width: 370px;
    height: 290px;
    margin-top: 300px;
    margin-bottom: -110px;
    margin-left: 8px;
    z-index: auto;
  }
  .t_box{
    margin-left: 103px;
  }
  .seja{
    width: auto;
  }
  .venda{
    width: auto;
  }
  .texto_quando{
    margin-left: -375px;
  }
}