@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}

.clip-content {
  clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000; /* Deve estar acima do backdrop */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

select {
  color: #9CA3AF;
}

select:hover,
select:focus,
select:active,
select:checked {
  color: #333;
}

select option {
  color: #333;
}